import { type AiOfferListRequestDTO, type AiOfferListResponseDTO } from 'ecosystem'
import { baseSplitApi } from '../../store'

export const getAiOffersEndpoint = baseSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAiOffers: builder.query<AiOfferListResponseDTO, AiOfferListRequestDTO>({
      query: (args) => ({
        url: `ai-offers/get-list`,
        method: 'POST',
        body: args
      }),
      transformResponse: (aiOffers: AiOfferListResponseDTO) => {
        return aiOffers.map((offer) => ({
          ...offer,
          products: offer.products?.map((offerProduct) => ({
            ...offerProduct,
            product: {
              ...offerProduct.product,
              price: offerProduct.discountedPrice,
              originalPrice: offerProduct.product.price
            }
          }))
        }))
      }
    })
  }),
  overrideExisting: true
})

export const { useGetAiOffersQuery } = getAiOffersEndpoint
