import { type AihubSizeStateFE, type AihubTabId } from 'ecosystem'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { aihubStorage } from 'shared-utils'

export interface AihubSliceState {
  // sessionUserId?: string | null
  // isInit: boolean
  // initError: string | null
  isOpen: boolean
  sizeState: AihubSizeStateFE
  activeTabId?: AihubTabId
}

const staticInitialState: AihubSliceState = {
  // sessionUserId: undefined,
  // isInit: false,
  // initError: null,
  isOpen: false,
  sizeState: 'MIN'
}

const aihubSlice = createSlice({
  name: 'aihub',
  initialState: () => {
    let sizeState = aihubStorage.getSizeState()
    sizeState =
      sizeState && ['MIN', 'MAX'].includes(sizeState)
        ? (sizeState as AihubSizeStateFE)
        : staticInitialState.sizeState

    return {
      ...staticInitialState,
      // sessionUserId: aihubStorage.getSessionUserId() ?? undefined,
      sizeState
    } as AihubSliceState
  },
  reducers: {
    // setInitError: (
    //   state: AihubSliceState,
    //   { payload: error }: PayloadAction<AihubSliceState['initError']>
    // ) => {
    //   state.initError = error
    // },
    // setIsInit: (
    //   state: AihubSliceState,
    //   { payload: isInit }: PayloadAction<AihubSliceState['isInit']>
    // ) => {
    //   state.isInit = isInit
    // },
    //
    // setSessionUserId: (
    //   state: AihubSliceState,
    //   { payload: sessionUserId }: PayloadAction<string | null>
    // ) => {
    //   state.sessionUserId = sessionUserId
    // },
    setIsOpen: (state: AihubSliceState, { payload: isOpen }: PayloadAction<boolean>) => {
      state.isOpen = isOpen
    },
    setSizeState: (
      state: AihubSliceState,
      { payload: sizeState }: PayloadAction<AihubSizeStateFE>
    ) => {
      state.sizeState = sizeState
    },
    setAihubActiveTabId: (
      state: AihubSliceState,
      { payload: tabId }: PayloadAction<AihubTabId>
    ) => {
      state.activeTabId = tabId
    }
  }
})

export const {
  // setIsInit,
  // setInitError,
  setSizeState: setAihubSizeState,
  setIsOpen: setAihubIsOpen,
  setAihubActiveTabId
} = aihubSlice.actions

export const selectAihub = (state: { aihub: AihubSliceState }) => state.aihub
// export const selectAihubIsInit = (state: { aihub: AihubSliceState }) => state.aihub.isInit
// export const selectAihubInitError = (state: { aihub: AihubSliceState }) =>
//   state.aihub.initError
export const selectAihubSizeState = (state: { aihub: AihubSliceState }) => state.aihub.sizeState
export const selectAihubIsOpen = (state: { aihub: AihubSliceState }) => state.aihub.isOpen
export const selectAihubActiveTabId = (state: { aihub: AihubSliceState }) => state.aihub.activeTabId

export default aihubSlice.reducer
