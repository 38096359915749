import type { GetProductsRes } from 'ecosystem/grand-connect'
import type { GetProductEndpointPayload } from 'api'
import { baseSplitApi } from '../../store'
import { setSelectedProducts } from '../../slices/products'

/** Use this endpoint for product list widgets */
export const getListProductsEndpoint = baseSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getListProducts: builder.query<GetProductsRes, GetProductEndpointPayload>({
      query: ({ filter, config }) => ({
        url: `products/get-products`,
        method: 'POST',
        body: {
          filter,
          config
        }
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          //  on success, it dispatches the data to the state
          dispatch(setSelectedProducts(data))
        } catch {
          dispatch(
            setSelectedProducts({
              content: [],
              totalPages: 0,
              totalElements: 0
            })
          )
        }
      }
    })
  })
})

export const { useLazyGetListProductsQuery } = getListProductsEndpoint
