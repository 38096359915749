import { type AiOfferDTO, type AiOfferListRequestDTO } from 'ecosystem'
import lodashKeyBy from 'lodash/keyBy'
import { baseSplitApi } from '../../store'

export const getNewAiOffersEndpoint = baseSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getNewAiOffers: builder.query<AiOfferDTO[], AiOfferListRequestDTO>({
      queryFn: async (args, { getState }, _extraOptions, fetchWithBQ) => {
        const response = await fetchWithBQ({
          // ?newOnes=true it's just for clear understanding in network panel
          url: `ai-offers/get-list?newOnes=true`,
          method: 'POST',
          body: args
        })

        if (response.error) {
          return {
            error: response.error
          }
        }

        const data = response.data as AiOfferDTO[] | undefined

        if (!data) {
          return {
            data: []
          }
        }

        const cachedOffers: AiOfferDTO[] =
          // @ts-expect-error -- name of endpoint
          baseSplitApi.endpoints.getAiOffers?.select?.(args)?.(getState())?.data ?? []
        const dictCachedOffers = lodashKeyBy(cachedOffers, 'id')

        const newOffers = data
          .filter((offer) => !dictCachedOffers[offer.id])
          .map((offer) => ({
            ...offer,
            products: offer.products?.map((offerProduct) => ({
              ...offerProduct,
              product: {
                ...offerProduct.product,
                price: offerProduct.discountedPrice,
                originalPrice: offerProduct.product.price
              }
            }))
          }))

        return {
          data: newOffers
        }
      },

      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const { data: newOffers } = await queryFulfilled

          dispatch(
            // @ts-expect-error --
            baseSplitApi.util.updateQueryData('getAiOffers', args, (draft: AiOfferDTO[]) => {
              if (Array.isArray(draft)) {
                const dictCachedOffers = lodashKeyBy(draft, 'id')

                const uniqNewOffers = newOffers.filter((offer) => !dictCachedOffers[offer.id])
                if (uniqNewOffers.length) {
                  draft.push(...uniqNewOffers)
                }
              }
            })
          )
        } catch (e) {
          /* empty */
        }
      }
    })
  }),
  overrideExisting: true
})

export const { useGetNewAiOffersQuery, useLazyGetNewAiOffersQuery } = getNewAiOffersEndpoint
