import type { GetProductsRes } from 'ecosystem/grand-connect'
import type { GetProductEndpointPayload } from 'api'
import { baseSplitApi } from '../../store'

/** Use this endpoint everywhere except for product lists that rely on redux products slice */
export const getProductsEndpoint = baseSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<GetProductsRes, GetProductEndpointPayload>({
      query: ({ filter, config }) => ({
        url: `products/get-products`,
        method: 'POST',
        body: {
          filter,
          config
        }
      })
    })
  })
})

export const { useGetProductsQuery } = getProductsEndpoint
