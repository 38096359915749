import { type AiOfferDTO, type AiOfferAcceptRequestFEDTO } from 'ecosystem'
import { arrayFindAndUpdateItem } from 'shared-utils'
import { baseSplitApi } from '../../store'

export const acceptAiOfferEndpoint = baseSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    acceptAiOffer: builder.mutation<void, AiOfferAcceptRequestFEDTO>({
      query: ({ offerId }) => ({
        url: `ai-offers/accept-offer`,
        method: 'POST',
        body: {
          offerId
        }
      }),
      onQueryStarted: async ({ offerId, sessionId }, { queryFulfilled, dispatch }) => {
        try {
          dispatch(
            baseSplitApi.util.updateQueryData(
              // @ts-expect-error -- name of endpoint
              'getAiOffers',
              { sessionId },
              (offers: AiOfferDTO[]) => {
                if (Array.isArray(offers)) {
                  arrayFindAndUpdateItem({
                    arr: offers,
                    compare: (offer) => offer.id === offerId,
                    cb: (offer) => {
                      offer.accepted = true
                    }
                  })
                }
              }
            )
          )

          await queryFulfilled
        } catch (e) {
          /* empty */
        }
      }
    })
  }),
  overrideExisting: true
})

export const { useAcceptAiOfferMutation } = acceptAiOfferEndpoint
