import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CartItem, CartState, DiscountDTO, ICartItemSingle, Language } from 'ecosystem'
import { arrayFindAndReplaceItem, parseIntSafe } from 'shared-utils'
import { v4 as uuidv4 } from 'uuid'
import {
  deleteCartSliceFromStorage,
  getCartSliceFromStorage,
  updateCartSliceInStorage
} from './utils'

const initialState: CartState = {
  // todo: check if cartLocale is used
  cartLocale: null,
  cartItems: [],
  cartTotal: 0,
  cartDiscountCode: null,
  cartStateUuid: null
}

export const sumCart = (cartItems: CartItem[]) => {
  let total = 0

  cartItems.forEach((item) => {
    if (item.type === 'SINGLE') {
      total += item.meta.singleType.product.price * (parseIntSafe(item.qty) ?? 1)
    } else if (item.type === 'GROUP') {
      item.meta.groupType.items.forEach((groupItem) => {
        total +=
          groupItem.product.price * (parseIntSafe(item.qty) ?? 1) * (groupItem.originalQty ?? 1)
      })
    }
  })

  return total
}

const slice = createSlice({
  name: 'cart',
  initialState: () => {
    return getCartSliceFromStorage() ?? initialState
  },
  reducers: {
    setCartLocale: (state, { payload: cartLocale }: PayloadAction<Language>) => {
      state.cartLocale = cartLocale
      return state
    },

    addToCart: (state, { payload: cartItem }: PayloadAction<CartItem>) => {
      if (cartItem.type === 'SINGLE' && !cartItem.meta.singleType.product.price) {
        return state
      }

      ;(() => {
        const existingCartItem = state.cartItems.find(({ id }) => id === cartItem.id)

        if (!existingCartItem) {
          state.cartItems.push(cartItem)
          return
        }

        if (cartItem.type !== existingCartItem.type) {
          arrayFindAndReplaceItem({
            arr: state.cartItems,
            newItem: cartItem,
            compare: (arrItem) => arrItem.id === cartItem.id
          })
          return
        }

        // todo: for we don't care about group items
        if (cartItem.type === 'SINGLE') {
          existingCartItem.qty = cartItem.qty

          if (cartItem.offerInfo) {
            existingCartItem.offerInfo = cartItem.offerInfo
          }

          ;(existingCartItem as ICartItemSingle).meta.singleType.product.price = Math.min(
            (existingCartItem as ICartItemSingle).meta.singleType.product.price,
            cartItem.meta.singleType.product.price
          )
        }
      })()

      state.cartTotal = sumCart(state.cartItems)
      state.cartStateUuid = uuidv4()

      updateCartSliceInStorage(state)
      return state
    },

    removeFromCart: (state, { payload: cartItem }: PayloadAction<CartItem>) => {
      const currentCartItems = [...state.cartItems]
      state.cartItems = [...currentCartItems.filter((e) => e.id !== cartItem.id)]
      state.cartTotal = sumCart(state.cartItems)
      state.cartStateUuid = uuidv4()

      updateCartSliceInStorage(state)
      return state
    },

    applyDiscountCode: (state, { payload: discountCode }: PayloadAction<DiscountDTO>) => {
      state.cartDiscountCode = discountCode
      state.cartStateUuid = uuidv4()

      updateCartSliceInStorage(state)
      return state
    },

    cleanCart: () => {
      deleteCartSliceFromStorage()

      return initialState
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { setCartLocale, addToCart, removeFromCart, applyDiscountCode, cleanCart } =
  slice.actions

// available selectors - add more as needed
export const selectedCart = (state: { cart: CartState }) => state.cart
export const selectedCartItems = (state: { cart: CartState }) => state.cart.cartItems
export const selectedCartDiscount = (state: { cart: CartState }) => state.cart.cartDiscountCode
// exports slice reducer
export default slice.reducer
